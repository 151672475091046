import React from "react"
import {Link} from "gatsby"
import {graphql} from "gatsby"
import Layout from "../layout"
import SEO from "../seo"
import Img from "gatsby-image"
import {slugify} from "../../helpers"
import {Donate, PrayerWidget} from "../../pages";

// This query needs to be not static for the pagination to work
export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulArticle(
        skip: $skip,
        limit: $limit
        ) {
      edges { 
         node {
            markdown {
              childMarkdownRemark {
                html
              }
            }
            id
            title
            mainPitch
            thumbnailImage {
              localFile {
                url
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
       }
    }
  }
`;


export const Pagination = ({pageContext, pathContext, rest}) => {
  const {previousPagePath, nextPagePath} = pageContext;

  const paginate = (size, startAt = 1) => {
    return [...Array(size).keys()].map(i => i + startAt);
  }

  return (<div className={` ${rest}`}>
    <div className={'flex justify-between mb-4'}>
      <div className={'mr-2'}>
        {previousPagePath && <Link className={'hover:underline'} to={previousPagePath}>← Newer</Link>}
      </div>
      <div className={'mx-4'}>
        {pathContext && paginate(pathContext.numberOfPages).map((page, index) =>
          <Link
            key={index}
            className={`border border-brand-main px-2 rounded mr-2 w-6 h-6 text-sm ${page === pathContext.humanPageNumber ? 'bg-brand-main text-white hover:bg-white hover:text-brand-main' : 'hover:bg-brand-main hover:text-white bg-white text-brand-main'}`}
            to={'/articles/page/' + (page === 1 ? '' : page)}>
            {page}
          </Link>
        )}
      </div>
      <div className={'mr-2'} style={{justifySelf: 'flex-end'}}>
        {nextPagePath && <Link className={'hover:underline'} to={nextPagePath}>Older →</Link>}
      </div>
    </div>
  </div>)
}

const ArticlesList = ({data, pathContext, pageContext}) => {
  const articles = data.allContentfulArticle.edges

  return (
    <Layout>
      <SEO title="Articles"/>
      <div className="mx-auto container font-sans flex flex-col items-center justify-around section">
        <h2 className="text-center uppercase text-xl text-navy-500 font-thin mt-4 mb-4 lg:mt-8">Blog</h2>
        <p className="max-w-3xl text-center mx-auto text-navy-500 px-4 mb-6 lg:mb-8">
          Our Mission is to promote an Islamic way of life, as described in the Holy Quran and the Sunnah of Prophet Muhammad (Salallahu Alayhi Wasallam), through worship, learning and socialization.
        </p>
        <div className="flex flex-wrap items-center justify-center">
          {articles.length > 0
            ? articles.filter(post => post.node.mainPitch !== true).map(({node: article}, i) => {
              return <Link to={'/articles/' + slugify(article.title)}
                           key={article.id}
                           aria-label={article.name + ' Gwinnett Islamic Circle'}
                           className="mx-0 text-center text-navy-600 lg:mb-6 mb-10 w-full lg:max-w-md lg:min-w-md mx-4">
                {article.thumbnailImage
                  ? <Img
                    imgStyle={{
                      objectFit: "cover",
                      objectPosition: "50% 0%",
                    }}
                    fluid={article.thumbnailImage.localFile.childImageSharp.fluid}
                    className="w-full mx-auto h-40 md:h-64 mb-4 rounded"/>
                  : <div className={"w-full mx-auto h-40 md:h-64 mb-4 rounded pt-14"}>No image</div>}
                <div className="font-bold text-lg py-2 h-20">{article.title}</div>
              </Link>
            })
            : <p className="max-w-3xl text-center mx-auto text-navy-500 px-4 mb-6 lg:mb-8">
              More articles coming soon.
            </p>
          }
        </div>
        <Pagination pathContext={pathContext} pageContext={pageContext}/>
      </div>
    </Layout>
  )
}

export default ArticlesList